<template>
  <div class="app-container">
    <div class="top_box">
      <el-form
        :inline="true"
        :model="queryForm"
        class="demo-form-inline account-top"
      >
        <el-form-item label="标签 :">
          <el-input
            v-model="queryForm.tag"
            placeholder="标签"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="所属用户 :" v-if="userInfo.is_staff">
          <el-input
            v-model="queryForm.owner"
            placeholder="所属用户ID"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="搜索" prop="">
          <el-input
            v-model="queryForm.search"
            placeholder="模糊检索..."
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="fetchData">刷新</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="bottom_box">
      <el-button @click="showAddDialog" size="mini" type="success">
        添加标签
      </el-button>
      <div class="content_t">
        <el-table
          v-loading="listLoading"
          :data="list"
          element-loading-text="Loading"
          border
          fit
          highlight-current-row
          class="tableList2"
          :header-cell-style="{ background: '#F3F7F9', color: '#909399' }"
          height="100%"
          style="width: 100%"
          algin="center"
          :default-sort="{ prop: 'create_time', order: 'descending' }"
          @sort-change="handleSort"
        >
          <el-table-column prop="id" label="ID" width="100"></el-table-column>
          <el-table-column prop="tag" label="标签"></el-table-column>
          <el-table-column label="创建人备注" v-if="isManager">
            <template slot-scope="scope">
              {{ scope.row.owner ? scope.row.owner.note : null }}
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            prop="create_time"
            sortable="custom"
          ></el-table-column>
          <el-table-column label="操作">
            <template v-slot="scope">
              <el-button
                @click="jumpToInterface(scope.row.id, 'message')"
                type="warning"
                size="mini"
              >
                消息({{ scope.row.message_count }})
              </el-button>
              <el-button
                @click="jumpToInterface(scope.row.id, 'group')"
                type="primary"
                size="mini"
              >
                群组({{ scope.row.group_count }})
              </el-button>
              <el-button
                @click="jumpToInterface(scope.row.id, 'user')"
                type="success"
                size="mini"
              >
                用户({{ scope.row.user_count }})
              </el-button>
              <el-button
                @click="deleteLabel(scope.row.id)"
                type="danger"
                size="mini"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="queryForm.page_index"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="queryForm.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="count"
      ></el-pagination>
    </div>
    <!-- 添加标签弹框 -->
    <el-dialog
      title="添加标签"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
        v-if="dialogVisible"
      >
        <el-form-item label="标签名称" prop="tag">
          <el-input v-model="ruleForm.tag"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">
            确定
          </el-button>
          <el-button @click="handleClose">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getTagList, addTagForAll, delTag } from '@/api/tagged'
import { getManagerStatus } from '@/utils/manager'
import { cacheMixin } from '@/mixins/mixin'
export default {
  name: 'tagManage',
  mixins: [cacheMixin],
  data() {
    return {
      userInfo: this.$store.state.user.userInfo,
      list: null,
      listLoading: true,
      count: 0,
      isManager: getManagerStatus(),
      queryForm: {
        page_index: 1,
        page_size: 10,
        search: '',
        owner: '',
        ordering: '-create_time'
      },
      dialogVisible: false,
      ruleForm: {
        tag: ''
      },
      rules: {
        tag: [{ required: true, message: '请输入标签', trigger: 'blur' }]
      }
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      this.listLoading = true
      const { count, items } = await getTagList(this.queryForm)
      this.count = count
      this.list = items
      this.listLoading = false
    },
    async handleSearch() {
      this.queryForm.page_index = 1
      await this.fetchData()
    },
    handleSizeChange(pageSize) {
      this.queryForm.page_size = pageSize
      // 分页变更时处理
      this.fetchData()
    },
    handleCurrentChange(pageIndex) {
      this.queryForm.page_index = pageIndex
      // 分页变更时处理
      this.fetchData()
    },
    // 跳转对应的界面
    jumpToInterface(label, path) {
      this.$router.push({
        path: `/tagged/${path}Tag`,
        query: {
          tag: label
        }
      })
    },
    // 弹出添加标签弹框
    showAddDialog() {
      this.dialogVisible = true
    },
    handleClose() {
      this.dialogVisible = false
    },
    // 添加
    submitForm(formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          await addTagForAll(this.ruleForm)
          this.handleClose()
          this.fetchData()
          this.ruleForm.tag = ''
        } else {
          return false
        }
      })
    },
    // 删除
    deleteLabel(id) {
      this.$confirm('此操作将永久删除该标签, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          await delTag(id)
          this.$message({
            type: 'success',
            message: '删除成功！'
          })
          this.fetchData()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    handleSort({ column, prop, order }) {
      if (order === null) {
        this.queryForm.ordering = null
      } else if (order === 'descending') {
        this.queryForm.ordering = '-' + prop
      } else {
        this.queryForm.ordering = prop
      }
      console.log(column, prop, order)
      this.fetchData()
    }
  }
}
</script>
<style lang="scss" scoped>
.top_box {
  width: 100%;
  padding: 10px;
  background: #fff;
  border: 1px solid #e5e5e5;
  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  button.el-button.el-button--primary {
    height: 32px;
    padding: 0 20px;
    // line-height: 32px;
  }
  .el-form-item {
    margin-bottom: 0;
    .el-input {
      padding: 0 15px;
      width: 250px;
      ::v-deep span.el-input__suffix {
        right: 20px;
      }
    }
    ::v-deep input.el-input__inner {
      height: 32px;
      line-height: 32px;
    }
  }
}

.bottom_box {
  width: 100%;
  height: calc(100vh - 178px);
  margin-top: 10px;
  border: 1px solid #e5e5e5;

  box-shadow: 0 1px 0px 0 #d8d9d9;
  border-radius: 5px;
  padding: 10px;
  .content_t {
    height: calc(100% - 70px);
    margin-top: 10px;
    // overflow: auto;
    ::v-deep td.el-table_1_column_1.el-table__cell {
      text-align: center;
    }
  }
}
</style>
